import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Checkbox from "../../../components/Checkbox";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/Loader";
import CustomDropdown from "../../../components/CustomDropdown";
import { addNotification } from "../../../components/Notification";

const Form = ({ validEmail, setValidEmail, options, signUpHandler, signUpTokenLoading, userData, captcha, setReCaptcha, reCaptcha, captchaLoading }) => {
  const [policy, setPolicy] = useState(userData ? userData.policy : false);
  const [email, setEmail] = useState(userData ? userData.email : "");
  const [password, setPassword] = useState(userData ? userData.password : "");
  const [referralCode, setReferralCode] = useState(window.location.href.split("ref=")[1]);

  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      in: "Confirm password should be matched!",
    },
  }));

  const [forceUpdate, setForceUpdate] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [text, setText] = useState("Select region");
  const [regionValue, setRegionValue] = useState();

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    };
    setPasswordType("password");
  };

  const base64data = btoa(unescape(encodeURIComponent(captcha?.buffer)));

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <div className={styles.top}>
        <h4 className={cn("h4", styles.title)}>Sign up</h4>
        <div className={styles.info}>
          Please ensure you are visiting the correct url.
        </div>
        <div className={styles.correct}>
          <Icon name="lock" size="24" />
          <div className={styles.url}>
            https://<span>bitdenex.com/sign-up</span>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <TextInput
          className={styles.field}
          label="Email Address"
          name="email"
          type="email"
          placeholder="Email Address"
          value={email ? email : ""}
          onChange={(e) => { setEmail(e.target.value); }}
          onBlur={() => {
            simpleValidator.current.showMessageFor("email");
            const newArr = process.env.REACT_APP_DISPOSABLE_EMAIL?.split(",");
            for (let index = 0; index < newArr.length; index++) {
              if (email?.split("@")[1]?.split(".")[0] === newArr[index]) {
                addNotification({
                  title: 'Alert',
                  message: "Disposable email is not allowed.",
                  type: 'danger'
                });
                setValidEmail(false);
                break;
              }
              else {
                setValidEmail(true);
              }
            }
          }
          }
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("email", email, "required|email")}
        </span>
      </div>
      <div className={styles.container}>
        <TextInput
          className={styles.field}
          label="Password"
          name="password"
          type={passwordType}
          placeholder="Password"
          value={password ? password : ""}
          onChange={(e) => { setPassword(e.target.value); }}
          required
          onBlur={() => { simpleValidator.current.showMessageFor("password") }}
          view
          togglePassword={togglePassword}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("password", password, `required|min:9`)}
        </span>
      </div>

      <CustomDropdown
        className={styles.dropdown}
        label="Select Region"
        value={regionValue}
        setValue={setRegionValue}
        text={text}
        setText={setText}
        options={options}
      />

      <div className={styles.container}>
        {!captchaLoading ? <img className={styles.captcha} src={`data:image/svg+xml;base64,${base64data}`} alt="" /> : <Loader className={styles.loader} />}

        <TextInput
          className={styles.field}
          name="captcha"
          placeholder="Enter captcha here"
          value={reCaptcha}
          onChange={(e) => { setReCaptcha(e.target.value); }}
          required
          onBlur={() => simpleValidator.current.showMessageFor("captcha")}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("captcha", reCaptcha, "required")}
        </span>
      </div>
      <div className={styles.container}>
        <TextInput
          label="Referral ID(Optional)"
          className={styles.field}
          name="referral"
          placeholder="Enter referral here"
          value={referralCode}
          onChange={(e) => { setReferralCode(e.target.value); }}
          maxLength={12}
          disabled={window.location.href.includes("ref=") ? true : false}
        />
      </div>
      <div className={styles.container}>
        <Checkbox
          className={styles.checkbox}
          value={policy ? policy : ""}
          onChange={() => {
            setPolicy(!policy);
            simpleValidator.current.showMessageFor("policy");
          }}
          content="<span>I agree that I’m 18+ years old, and accept the <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>, <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>, <a href='/kyc-policy' target='_blank' rel='noopener noreferrer'>KYC Policy</a>.<span>"
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("policy", policy, "accepted")}
        </span>
      </div>
      <button
        className={cn("button", styles.button)}
        disabled={signUpTokenLoading}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (text.toLowerCase() !== "select region") {
              signUpHandler(email, password, policy, captcha?.captchaId, regionValue, referralCode);
            }
            else {
              addNotification({
                title: 'Alert',
                message: "Please select a region value.",
                type: 'danger'
              });
            }
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }

        }}>
        Sign up
      </button>
    </form >
  );
};

export default Form;
