import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { Link } from "react-router-dom";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../../components/helper";
import PanelSkeleton from "../../../../components/Skeleton/PanelSkeleton";

const Panel = ({
  activeIndex,
  setActiveIndex,
  loading,
  setDataMarkets,
  socketMarketData
}) => {

  return (
    <div className={styles.panel}>
      <div className={styles.body}>
        <div className={styles.list}>
          {loading ? <PanelSkeleton rowCount={3} />
            :
            socketMarketData?.filter(x => x.currencyId === activeIndex).map((x, index) => {
              return x.markets.slice(0, 3).map((i, count) => (
                <Link className={styles.item} key={count}
                  to={"/sign-up"}
                >
                  <div className={styles.icon}>
                    <img src={i.icon} alt="Currency" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{i.slug}</div>
                      {getPostiveNegativeNum(i.dayChange) ?
                        <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                        :
                        <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                      }
                    </div>
                    <div className={styles.price}>{getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}</div>
                  </div>
                </Link>
              ))
            })}
        </div>
      </div>
      <div className={styles.foot}>
        <div className={styles.nav}>
          {socketMarketData.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x.currencyId === activeIndex,
              })}
              onClick={() => { setActiveIndex(x.currencyId); setDataMarkets(x.markets) }}
              key={index}
            >
              {x.currency}
            </button>
          ))}
        </div>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to={"/sign-up"}
        >
          Trade
        </Link>
      </div>
    </div>
  );
};
export default Panel;