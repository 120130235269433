import React, { useEffect, useLayoutEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { socketDataReceived } from "../../actions/markets";

const Market = () => {

  const [dataMarkets, setDataMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);
  const { socketMarketData } = useSelector((state) => { return state.markets });
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ column: null, order: 'ASC' });
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    socket.connect(() => { });
    socket.connect();
    socket.on("all_markets_web", (markets) => {
      dispatch(socketDataReceived(markets));
      setLoading(false);
    });

    return () => {
      socket.off("all_markets_web");
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socketMarketData?.length && activeColumn?.key === "") {
      setFilteredData(socketMarketData);
    }
  }, [socketMarketData]);

  useEffect(() => {
    setFilteredData(socketMarketData);
  }, [activeIndex])

  const handleSort = (columnName, filteredData) => {
    let marketDataMain = [...filteredData];
    let isMarketKeyPresent = filteredData.some(obj => "markets" in obj);
    if (isMarketKeyPresent) {
      let newFilteredData = filteredData?.filter(x => x.currencyId === activeIndex);
      marketDataMain = newFilteredData[0]?.markets
    }
    let newSortedData = filteredData
    if (columnName === '') return newSortedData
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? marketDataMain?.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : marketDataMain.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "weekChange") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["weekChange"] > b["weekChange"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["weekChange"] < b["weekChange"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "marketCap") {
      const sortedData = [...filteredData].sort((a, b) => {
        const capA = parseInt(a?.cap?.replace(/,/g, ''), 10);
        const capB = parseInt(b?.cap?.replace(/,/g, ''), 10);

        return order === 'ASC' ? capA - capB : capB - capA;
      });
      newSortedData = sortedData;
    }
    else if (columnName === "volume") {
      const sortedData = [...filteredData].sort((a, b) => {
        const volumeA = parseInt(a?.volume?.replace(/,/g, ''), 10);
        const volumeB = parseInt(b?.volume?.replace(/,/g, ''), 10);

        return order === 'ASC' ? volumeA - volumeB : volumeB - volumeA;
      });
      newSortedData = sortedData;
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
    setFilteredData(newSortedData);
  };

  return (
    <>
      <Main />
      <Details
        socketMarketData={socketMarketData}
        loading={socketMarketData?.length <= 0 && loading}
        setDataMarkets={setDataMarkets}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        dataMarkets={dataMarkets}
        handleSort={handleSort}
        search={search}
        setSearch={setSearch}
        activeColumn={activeColumn}
        filteredData={filteredData}
      />
    </>
  );
};

export default Market;
