import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Trade from "./Trade";
// import Notice from "./Notice";

const Details = ({
  loading,
  dataMarkets,
  setDataMarkets,
  activeIndex,
  setActiveIndex,
  socketMarketData,
  handleSort,
  search,
  setSearch,
  activeColumn,
  filteredData
}) => {

  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Panel
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          loading={loading}
          setDataMarkets={setDataMarkets}
          socketMarketData={socketMarketData}
        />
        <Trade
          loading={loading}
          socketMarketData={socketMarketData}
          dataMarkets={dataMarkets}
          setDataMarkets={setDataMarkets}
          activeIndex={activeIndex}
          handleSort={handleSort}
          search={search}
          setSearch={setSearch}
          activeColumn={activeColumn}
          filteredData={filteredData}
        />
        {/* <Notice /> */}
      </div>
    </div>
  );
};

export default Details;
