import {
    SOCKET_DATA_RECEIVED,
} from '../actions/markets/actionType';

const initialState = {
    socketMarketData: [],
    connected: false,
    loading: true,
};

const MarketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_DATA_RECEIVED:
            return {
                ...state,
                loading: false,
                socketMarketData: action.payload,
            };
        default:
            return state;
    }
};

export default MarketReducer;